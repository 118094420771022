import React from "react";
import { graphql } from "gatsby";

import { HomeBioSection } from "../components/home-bio-section";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    prismicProfilePage: { data },
  },
}) {
  return (
    <>
      <HomeBioSection data={data} />
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query profile($uid: String!) {
    prismicProfilePage(uid: { eq: $uid }) {
      uid
      data {
        image {
          alt
          copyright
          url
          thumbnails
          fluid(imgixParams: { faces: 1 }) {
            src
          }
        }
        profile {
          raw
        }
      }
    }
  }
`;
