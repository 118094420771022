import React from "react";
import { Wrapper } from "./style/global-styles";
import styled from "styled-components";
import Box from "./box";
import ImageBox from "./image-box";
import { clampBuilder } from "../functions/util";
import AnimatedText from "./animated-text";

export function HomeBioSection({ data: { profile, image } }) {
  return (
    <BioWrapper data-inverse-reverse={true}>
      <BioInner>
        <BioText>
          {profile.raw.map(({ type, text }, i) => {
            if (type === "heading1") {
              return (
                <AnimatedText
                  key={i}
                  text={text}
                  staggerChildren={0.025}
                  className="h1"
                />
              );
            } else {
              return (
                <AnimatedText key={i} text={text} staggerChildren={0.0035} />
              );
            }
          })}
        </BioText>
        <ImageBox className="image" fluid={image.fluid} />
      </BioInner>
    </BioWrapper>
  );
}
const BioWrapper = styled(Wrapper)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  padding-top: 12vmax;
  padding-bottom: 8vw;
`;

const BioInner = styled(Box)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media ${(props) => props.theme.device.laptop} {
    flex-direction: row;
  }

  .h1 {
    font-size: ${clampBuilder(2, 4.25)};
    line-height: 1.2;
    max-width: 15em;
    margin-bottom: 1em;
  }

  p {
    max-width: 36em;
    white-space: break-spaces;
  }

  .image {
    height: 120vw;
    min-width: 30vw;
    margin-bottom: 8vw;

    @media ${(props) => props.theme.device.laptop} {
      height: 45vw;
      width: 30vw;
      margin-bottom: 0;
    }
  }
`;

const BioText = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
`;
